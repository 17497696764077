<template>
  <div class="elite-tabs-wrapper-content">
    <div class="col-12">

      <div class="credit-debit-details">
        <h3>{{ $t("DPA_PPA.DPA") }}</h3>
        <object-details
          :data="getDetails('credit')"
          :with-trad="true"
          trad-path="DPA_PPA"
        />
        <el-divider />
        <h3>{{ $t("DPA_PPA.PPA") }}</h3>
        <object-details
          :data="getDetails('debit')"
          :with-trad="true"
          trad-path="DPA_PPA"
        />
      </div>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("DPA_PPA.TRANSACTION_CALLBACK_URL") }}</dt>
        <dd class="col-sm-8">
          {{ dpaAccount.transaction_callback_url }}
        </dd>
      </dl>

      <dl
        class="row"
        v-if="dpaAccount.organization && $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="dpaAccount.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ dpaAccount.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ dpaAccount.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
  import {
  Divider
} from "element-ui";
import ObjectDetails from "@/components/ElixioWallet/components/atoms/ObjectDetails.vue";
export default {
  name: "dpa-account-view-global",

  components: {
    [Divider.name]: Divider,
    ObjectDetails
  },

  props: ["dpaAccount"],

  data() {
    return {};
  },

  created() {},

  methods: {
    dpaAccountUpdated() {
      this.$emit("dpaAccountUpdated", true);
    },
    
    getDetails(type) {
      if(type=='credit'){
        return {
          ISSUER_NUMBER: this.dpaAccount.credit_issuer_number,
          DATA_CENTER_CODE: this.dpaAccount.credit_data_center_code,
          BANK_FORMAT: this.dpaAccount.credit_bank_format,
          FILE_NAME: this.dpaAccount.credit_file_name,
        };
      }
      return {
        ISSUER_NUMBER: this.dpaAccount.debit_issuer_number,
        DATA_CENTER_CODE: this.dpaAccount.debit_data_center_code,
        BANK_FORMAT: this.dpaAccount.debit_bank_format,
        FILE_NAME: this.dpaAccount.debit_file_name,
      };
    },
  },

  mounted() {},

  watch: {
    dpaAccount(dpaAccount) {},
  },
};
</script>
